<template>
  <div>
    <label
      v-if="title"
      class="font-weight-bolder font-medium-5 text-body"
    >{{ $t(title) }}
    </label>
    <b-row>
      <b-col
        v-for="item in Object.keys(list)"
        :key="item"
        md="4"
        class="integration-item-col"
        @click="gotoNewPage({ name: list[item].link }, $event)"
      >
        <div
          class="integration-item-image"
        >
          <img
            :src="getImage(list[item].image)"
          >
        </div>
        <div class="text-center">
          {{ list[item].title }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'Item',
  components: {
    BRow,
    BCol,
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    getImage(name) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/images/integrations/${name}`)
    },
  },
}
</script>

<style scoped>
.integration-item-col {
  margin-bottom: 16px;
  cursor: pointer;
}
.integration-item-image {
  height: 80px;
  width: 90%;
  background: white;
  margin: auto;
  border-radius: 5px;
  border: solid 1px #A5A5A5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.integration-item-image img {
  display: block;
  margin: 0 auto;
  width: 50%;
  max-height: 90%;
}
</style>
