<template>
  <div>
    <p
      class="font-weight-bolder font-large-1 color-black"
    >
      {{ $t('Protocols') }}
    </p>
    <b-row>
      <b-col md="5">
        <item
          :list="emailList"
          title="Email"
        />
      </b-col>
      <b-col md="1" />
      <b-col md="5">
        <item
          :list="singleSignOnList"
          title="Single Sign-On"
        />
      </b-col>
    </b-row>
    <p
      class="font-weight-bolder font-large-1 pt-2 color-black"
    >
      {{ $t('API Integrations') }}
    </p>
    <b-row v-if="PaymentGatewayList">
      <b-col md="5">
        <payment-gateway-list
          :list="PaymentGatewayList"
          title="Payment Gateways"
        />
      </b-col>
      <b-col md="1" />
      <b-col md="5">
        <item
          :list="ecommerceList"
          title="e-Commerce Frontends"
        />
      </b-col>
      <b-col md="5">
        <!--              <item-->
        <!--                :list="messagingList"-->
        <!--                title="Messaging"-->
        <!--              />-->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5">
        <item
          :list="verificationList"
          title="Verifications"
        />
      </b-col>
      <b-col md="1" />
      <b-col md="5">
        <label
          class="font-weight-bolder font-medium-5 text-body"
        >{{ $t('Shipping') }}</label>
        <!--        <item-->
        <!--          :list="accountingList"-->
        <!--          title="Accounting"-->
        <!--        />-->
      </b-col>
    </b-row>
    <b-row>
      <!--      <b-col md="5">-->
      <!--        <item-->
      <!--          :list="ecommerceList"-->
      <!--          title="e-Commerce Frontends"-->
      <!--        />-->
      <!--      </b-col>-->
      <b-col md="1" />
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Item from '@/views/settings/general/integrations/list/Item.vue'
import PaymentGatewayList from '@/views/settings/general/integrations/view/component/PaymentGatewayList.vue'

export default {
  name: 'List',
  components: {
    PaymentGatewayList,
    Item,
    BRow,
    BCol,
  },
  computed: {
    PaymentGatewayList() {
      return this.$store.state[this.MODULE_NAME_PAYMENT_GATEWAYS].paymentGateways
    },
  },
  setup() {
    const MODULE_NAME_PAYMENT_GATEWAYS = 'payment-gateways'
    const getFullRouteName = routeName => `settings-general-integrations-${routeName}`

    const emailList = {
      smtp: {
        image: 'smtp.svg',
        link: getFullRouteName('smtp'),
        title: 'SMTP',
      },
      imap: {
        image: 'smtp.svg',
        link: getFullRouteName('imap'),
        title: 'IMAP',
      },
    }
    const singleSignOnList = {
      keyCloak: {
        image: 'keycloak.svg',
        link: getFullRouteName('keycloak'),
        title: 'KeyCloak',
      },
    }
    const paymentGetwayList = {
      cardKnox: {
        image: 'cardnox.svg',
        link: getFullRouteName('cardknox'),
        title: 'Cardknox',
      },
    }
    const messagingList = {
      slack: {
        image: 'slack.svg',
        link: getFullRouteName('slack'),
        title: 'Slack',
      },
    }
    const accountingList = {
      quickbooks: {
        image: 'quickbooks.svg',
        link: getFullRouteName('quickbooks'),
        title: 'Quickbooks',
      },
    }
    const verificationList = {
      idscan: {
        image: 'id.svg',
        link: getFullRouteName('idscan'),
        title: 'IDScan.net',
      },
      telesign: {
        image: 'telesign.svg',
        link: getFullRouteName('telesign'),
        title: 'Telesign',
      },
      // tloxp: {
      //   image: 'tloxp.svg',
      //   link: getFullRouteName('tloxp'),
      //   title: 'TLOxp',
      // },
      usps: {
        image: 'usps.svg',
        link: getFullRouteName('usps'),
        title: 'USPS',
      },
      emailage: {
        image: 'emailage.svg',
        link: getFullRouteName('emailage'),
        title: 'Emailage',
      },
    }
    const ecommerceList = {
      cscart: {
        image: 'cs_cart.svg',
        link: getFullRouteName('cs-cart'),
        title: 'CS-Cart',
      },
    }
    return {
      emailList,
      singleSignOnList,
      paymentGetwayList,
      messagingList,
      MODULE_NAME_PAYMENT_GATEWAYS,
      accountingList,
      verificationList,
      ecommerceList,
    }
  },
}
</script>

<style scoped>
  .color-black {
    color: black;
  }
</style>
