<template>
  <div>
    <label
      v-if="title"
      class="font-weight-bolder font-medium-5 text-body"
    >{{ $t(title) }}
    </label>
    <b-row>
      <b-col
        v-for="item in Object.keys(list)"
        :key="item"
        md="4"
        class="integration-item-col"
        @click="gotoNewPage(list[item].id)"
      >
        <div
          class="integration-item-image"
        >
          <img
            alt="Payment Gateway"
            :src="cardknox"
          >
        </div>
        <div class="text-center">
          {{ list[item].name }}
        </div>
      </b-col>
      <!--      <b-col-->
      <!--        md="4"-->
      <!--        class="integration-item-col"-->
      <!--        @click="gotoCratePage()"-->
      <!--      >-->
      <!--        <div class="integration-item-image">-->
      <!--          <img-->
      <!--            alt="Payment Gateway"-->
      <!--            :src="plus"-->
      <!--          >-->
      <!--        </div>-->
      <!--      </b-col>-->
    </b-row>
  </div>
</template>
<script>

import { BCol, BRow } from 'bootstrap-vue'
import cardknox from '../../../../../../assets/images/integrations/cardnox.svg'
import plus from '../../../../../../assets/images/icons/svg.icons/white/plus.svg'

export default {
  name: 'PaymentGatewayList',
  components: {
    BRow,
    BCol,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cardknox,
      plus,
    }
  },
  methods: {
    gotoNewPage(itemId) {
      this.$router.push({
        name: 'settings-general-integrations-cardknox-view',
        params: { id: itemId },
      })
    },
    // gotoCratePage() {
    //   this.$router.push({
    //     name: 'settings-general-integrations-cardknox-create',
    //   })
    // },
  },
}
</script>

<style scoped>
.integration-item-col {
  margin-bottom: 16px;
  cursor: pointer;
}
.integration-item-image {
  height: 80px;
  width: 90%;
  background: white;
  margin: auto;
  border-radius: 5px;
  border: solid 1px #A5A5A5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.integration-item-image img {
  display: block;
  margin: 0 auto;
  width: 50%;
  max-height: 90%;
  height: 80px;
}
</style>
